import { EmptySearchResult } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AppLink } from '../../../../core/components/link/link';
import { AppTextStyle } from '../../../../core/components/text/text-style/TextStyle';
import { LoadingLayout } from '../../../../core/layouts/loading-layout/loading-layout';
import { ordersApi } from '../../../api/orders.api';
import './order-details-layout.scss';

type ShopifyOrderDetailsLayoutProps = { id: number };

enum StatusMessages {
  SUCCESS = 'Redirecting...',
  FETCHING = 'Fetching order details...',
  NOT_FOUND = 'Order was not found by given ID',
  UNEXPECTED_ERROR = 'An unexpected error has occurred. Please try again later',
}

export const ShopifyOrderDetailsLayout = ({ id }: ShopifyOrderDetailsLayoutProps) => {
  const [convertedId, setConvertedId] = useState<string | undefined>();
  const [statusMessage, setStatusMessage] = useState(StatusMessages.FETCHING);

  const convertShopifyIdToCrowdshipId = useCallback((id: number) => {
    ordersApi
      .convertOrderId(id)
      .then(({ data }) => {
        if ('notFound' in data) {
          setStatusMessage(StatusMessages.NOT_FOUND);
        } else {
          setConvertedId(data.id);
          setStatusMessage(StatusMessages.SUCCESS);
        }
      })
      .catch((error) => {
        console.error(error);
        setStatusMessage(StatusMessages.UNEXPECTED_ERROR);
      });
  }, []);

  useEffect(() => {
    convertShopifyIdToCrowdshipId(id);
  }, [convertShopifyIdToCrowdshipId, id]);

  if (convertedId) return <Redirect to={`/orders/${convertedId}?fromShopify=true`} />;
  if ([StatusMessages.FETCHING, StatusMessages.SUCCESS].includes(statusMessage))
    return <LoadingLayout text={statusMessage} />;
  return (
    <div className="empty-order-page">
      <EmptySearchResult title={statusMessage} withIllustration />
      {statusMessage === StatusMessages.NOT_FOUND && (
        <AppTextStyle variation="subdued">
          Try searching for the order from the <AppLink url="/orders">Order list</AppLink>
        </AppTextStyle>
      )}
    </div>
  );
};
